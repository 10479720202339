
.App-header {
  height:100%;
  padding: 20px;
  padding-top: 60px;
  
  border-bottom: 1px solid gray;
}


.timeline.timeline--animate{
  max-width: 80%;
}

ul, li {
  margin: 0; 
  padding: 0;
}

.experience-image-logo{
  float:left;
  margin-right: 16px;
  width: 200px;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.experience-title{
  padding-top: 20px;
}
.experience-title-col{  
  overflow: hidden;
  margin-bottom: 24px;
}
.experience-content .experience-paragraph:not(:first-child) {
  margin-top: 12px;
}
.experience-paragraph{
  display: block;
}

.timeline-item-date{
  min-width: min(65vw, 180px);
}
a{
  color: rgb(77, 112, 173);
}
.headline a{
  margin-left: 5px;
}
.timeline-item-dateinner, .MuiTypography-root{
  font-feature-settings: 'pnum' on, 'lnum' on;
}